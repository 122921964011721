import api from '../../utils/api'

// 发送模拟消息
export async function mock_messages(payload) {
  return api.post(`/api/v1/operation/mock/plan_messages`, payload)
}





