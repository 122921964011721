<template>
  <div class="g_page_box page_box" style="background: #f2f7f8">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <div class="xh_box">
        <img src="../../assets/img/xh/img-xh.png" width="80%" />
        <h3 v-if="isFinish">消息已发送</h3>
        <h3 v-if="!isFinish">消息正在发送</h3>
        <p v-if="isFinish">
          请留意微信推送，此页面即将关闭
          <van-count-down class="time" :time="5000" @finish="finish" /><br />
        </p>
      </div>
    </div>
    <div class="bottom_box" v-if="isFinish">
      <p style="text-align: center; margin: 20px">
        如没收到请点击下方按钮手动发送
      </p>
      <div class="btn_box">
        <div class="btn" style="margin-bottom: 10px" @click="get_detail('man')">
          手动发送
        </div>
        <div class="btn" style="margin-bottom: 10px" @click="close">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSession } from "@/utils/session"
import { Dialog } from "vant"
import { mock_messages } from "./service"
import { sendBy3th } from "../mock-article/service"
import { get_url_params } from "../../utils/format"

export default {
  data() {
    return {
      loading: false,
      id: this.$route.query.id,
      plan_type: this.$route.query.plan_type,
      data: {},
      isFinish: false,
      disabled: false,
      autoMockSend: GLOBAL_CONFIG.autoMockSend,
    }
  },
  created() {
    if (this.autoMockSend === true) {
      this.get3thCode()
    } else {
      this.get_detail()
    }
  },
  methods: {
    async get3thCode() {
      if (this.autoMockSend !== true) return
      //wx 登录
      const query = {
        ...get_url_params(location.href.split("#")[0]),
        ...(this.$route.query || {}),
      }
      if (query.code) {
        const { plan_type, id } = query
        // 推送
        const { data } = await sendBy3th({
          plan_id: +id,
          plan_type: +plan_type,
          vx_code: query.code,
        })
        console.log(data)
        this.isFinish = true
        return
      }
      if (!query.code) {
        const redirect_uri = encodeURIComponent(
          `${
            GLOBAL_CONFIG.url3th
          }/#/third-redirect?redirect_uri=${encodeURIComponent(location.href)}`
        )
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${GLOBAL_CONFIG.appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123&component_appid=${GLOBAL_CONFIG.component_appid}#wechat_redirect`
      }
    },
    async get_detail(option) {
      if (option && this.disabled) {
        return
      }
      this.loading = false
      const authToken = getSession()
      console.log(authToken)
      if (!authToken) {
        return
      }
      try {
        let params = {
          plan_type: Number.parseInt(this.plan_type),
          plan_id: Number.parseInt(this.id),
          user_id: authToken.user_id,
        }
        const { data } = await mock_messages(params)
        if (!data) {
          console.log("!!")
          this.isFinish = true
          if (option) {
            this.disabled = true
          }
        }
      } finally {
        this.loading = false
      }
    },
    finish() {
      Dialog.confirm({
        title: "提示",
        message: "消息已发送，是否关闭此页面",
      })
        .then(() => {
          // on confirm
          this.close()
        })
        .catch(() => {
          // on cancel
        })
      //   this.$router.replace({ path: "/my-order", query: { order_state: 5 } });
    },
    close() {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          WeixinJSBridge.call("closeWindow")
        },
        false
      )
      WeixinJSBridge.call("closeWindow")
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page_box {
  background: #f7f7f7;
  height: 100vh;
}
.main_content {
  padding: 0 16px;
}

.xh_box {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  flex-wrap: wrap;
  color: #333;
  & img {
    margin-top: 10%;
  }
  & h3 {
    width: 100%;
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin: 10px auto;
  }
  & p {
    text-align: center;
    line-height: 1.6;
  }
}

.bottom_box {
  padding: 24px 13px;
  & .btn_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .btn {
      width: 48%;
      background: #0088ff;
      border-radius: 9px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      &.disabled {
        background: #ccc;
      }
    }
  }
}
</style>
