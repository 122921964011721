import api from "../../utils/api"
// 登录
export async function to_simulation(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data:null
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/operation/mock/plan_messages`, payload)
}

// 模拟计划消息发送-三方平台
export const sendBy3th = (params) => api.post("/api/v1/operation/mock/mock_send_message_by_openid", params)
